html {
    height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    /*background: rgb(36,179,105);*/
    /*background: linear-gradient(180deg, rgb(218, 255, 218) 20%, rgba(255,255,255,1) 100%);*/
    /*background-repeat: no-repeat;*/
    /*background-attachment: fixed;*/
    background: url(app/background-wash.png) no-repeat fixed;
    background-size: cover;
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
